import React from "react"
import { Link } from "gatsby"
import Hbg from "../../images/hero-img.jpg"
const hero = ({ location, background }) => {
  // console.log(backgrounBG)
  return (
    <div id="hero">
      <div
        className={`hero-bg ${
          location.pathname.includes("obt2020") ? "obt-bg" : ""
        }`}
        style={{
          backgroundImage:
            location.pathname !== "/" ? `url(${background})` : `url(${Hbg})`,
          backgroundPosition: location.pathname.includes("obt2020")
            ? "top"
            : "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div className="container hero-wrap px-5 px-md-3">
        <div className="row">
          {location.pathname.includes("obt2020") ? (
            <div className="col-md-9 obt-hero m-auto text-center bg-obt col-sm-12">
              <h4 className="text-orange pb-2" style={{ letterSpacing: "5px" }}>
                The NARM Training Institute Presents
              </h4>
              <h2 className="text-primary py-4">Online NARM Basics Training</h2>
              <hr />
              <h4 className="text-primary obt-desc">
                A Level 1 Professional Training that teaches <br />{" "}
                <strong> The NeuroAffective Relational Model</strong> <br /> for
                Healing Complex Trauma
              </h4>
            </div>
          ) : (
            <div className="col-md-7 col-sm-12">
              <div className="hero-content">
                <h1 className="text-narmblue font-weight-medium">
                  Welcome to the NARM<sup>®</sup> Training Institute
                </h1>
                <h4 className="text-narmblue font-weight-normal mt-4">
                  We are passionate about supporting professionals around the
                  world with our innovative trauma-informed training.
                </h4>

                <h4 className="text-orange font-weight-normal mt-4">
                  NARM is one of the first models specifically designed to
                  address C-PTSD that can support professionals in the growing
                  trauma-informed care field.
                </h4>
                <p className="mt-5 d-flex flex-column flex-sm-row justify-content-start">
                  <Link
                    className="btn btn-primary shadow arrow"
                    to="#join-newsletter"
                  >
                    Sign Up Today!
                  </Link>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default hero
