import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link, graphql } from "gatsby"
// import { Container } from "reactstrap"
import Swiper from "@components/swiper/swiper"
import PodCastSwiper from "@components/swiper/swiper"
// import SampleForm from "@components/form/form"
import Hero from "@components/hero"
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const Schedule = ({ data }) => {
  const trainings = data.allSanityTraning.edges
  const upcoming = trainings.filter(
    item => item.node.category[0].title === "Upcoming"
  )
  const current = trainings.filter(
    item => item.node.category[0].title === "Current"
  )

  console.log(trainings)
  return (
    <Layout>
      <SEO
        title="North America Trainings"
        description="The NARM Practitioner Training is designed for psychotherapists, social workers, counselors and mental health professionals who work with trauma. Graduate trainees and interns are welcome to apply."
      />
      <div class="page-headline">
        <div class="container">
          <div class="section-heading text-center">
            <h5 class="font-weight-bold text-uppercase text-orange flair 50">
              NORTH AMERICA TRAININGS
            </h5>
            <h1>Announcing The Online NARM Basics Training!</h1>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="container">
          <section id="trainingspage" className="component py-0">
            <div className="container px-5 px-md-3">
              <div className="intro mb-5">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="card shadow h-100">
                      <div
                        className="card-img"
                        style={{
                          backgroundImage:
                            'url("/assets/img/online-narm-basic-training.png")',
                        }}
                      >
                        <div className="card-link">
                          <a href="#">
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/img/play.svg"
                              width={14}
                              height={14}
                            />
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange" />
                        <h4 className="card-title text-primary">
                          Online NARM Basics Training
                        </h4>
                        {/*<div className="card-created">
													<p className="text-muted mb-0">
														<LazyLoadImage
															effect="blur"
															src="/assets/img/calendar.svg"
															width={20}
															height={20}
														/>
														<span className="ml-2">Jul 2019 - Jun 2020</span>
													</p>
												</div> */}
                      </div>
                      <Link
                        className="hidden-link"
                        to="/obt2020"
                        // target="_blank"
                        // rel="noopener noreferrer"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-8">
                    <p className="text-muted">
                      We are excited to announce this brand new training, which
                      makes learning NARM more accessible during a time when
                      trauma-informed care is needed more than ever before.
                      During this time of COVID-19, in-person professional
                      trainings are not an option. This live online professional
                      training is designed for those of you in mental health,
                      healthcare, addiction treatment, education, coaching, and
                      other fields working with clients or populations dealing
                      with Complex Trauma.
                    </p>
                    <Link
                      to="/obt2020"
                      //   target="_blank"
                      //   rel="noopener noreferrer"
                    >
                      Click here to read more about the Online NARM Basics
                      Training and to apply!
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mx-auto text-center mb-5">
                <h2 className="text-primary">
                  <span className="text-orange">Upcoming NARM Trainings</span>
                  &nbsp; 2020-2021
                </h2>
                <hr />
              </div>
              <div className="row">
                {upcoming.map(({ node }) => {
                  return (
                    <div key={node.id} className="col-md-6 col-lg-4 mb-4">
                      <div className="card shadow h-100">
                        <div
                          className="card-img"
                          style={{
                            backgroundImage: `url(${
                              node.mainBanner !== null
                                ? node.mainBanner.asset.fluid.src
                                : "https://placeimg.com/600/400/nature"
                            })`,
                          }}
                        >
                          <div className="card-link">
                            <Link
                              to={
                                node.slug !== null
                                  ? "/trainings/" + node.slug.current
                                  : ""
                              }
                            >
                              <LazyLoadImage
                                effect="blur"
                                src="/assets/img/play.svg"
                                width={14}
                                height={14}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
                            {node.area}
                          </h6>
                          <h4 className="card-title text-primary">
                            {node.title ==
                            "The NARM Practitioner Training for Attachment, Relational and Developmental Trauma"
                              ? "The NARM Practitioner Training"
                              : node.title}
                          </h4>
                          <div className="card-created">
                            <p className="text-muted mb-0">
                              <LazyLoadImage
                                effect="blur"
                                src="/assets/img/calendar.svg"
                                width={20}
                                height={20}
                              />
                              <span className="ml-2">{node.beginningDate}</span>
                            </p>
                          </div>
                        </div>
                        <Link
                          className="hidden-link"
                          to={
                            node.slug !== null
                              ? "/trainings/" + node.slug.current
                              : ""
                          }
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="col-md-12 mx-auto text-center my-5">
                <h2 className="text-primary">
                  <span className="text-orange">Current NARM Trainings</span>
                  &nbsp; 2020
                </h2>
                <hr />
              </div>
              <div className="row">
                {current.map(({ node }) => {
                  return (
                    <div key={node.id} className="col-md-4 mb-4">
                      <div className="card shadow h-100">
                        <div
                          className="card-img"
                          style={{
                            backgroundImage: `url(${
                              node.mainBanner !== null
                                ? node.mainBanner.asset.fluid.src
                                : "https://placeimg.com/600/400/nature"
                            })`,
                          }}
                        >
                          <div className="card-link">
                            <Link
                              to={
                                node.slug !== null
                                  ? "/trainings/" + node.slug.current
                                  : ""
                              }
                            >
                              <LazyLoadImage
                                effect="blur"
                                src="/assets/img/play.svg"
                                width={14}
                                height={14}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
                            {node.area}
                          </h6>
                          <h4 className="card-title text-primary">
                            {node.title ==
                            "The NARM Practitioner Training for Attachment, Relational and Developmental Trauma"
                              ? "The NARM Practitioner Training"
                              : node.title}
                          </h4>
                          <div className="card-created">
                            <p className="text-muted mb-0">
                              <LazyLoadImage
                                effect="blur"
                                src="/assets/img/calendar.svg"
                                width={20}
                                height={20}
                              />
                              <span className="ml-2">{node.beginningDate}</span>
                            </p>
                          </div>
                        </div>
                        <Link
                          className="hidden-link"
                          to={
                            node.slug !== null
                              ? "/trainings/" + node.slug.current
                              : ""
                          }
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Schedule

export const schedulePageQuery = graphql`
  query schedulePageQuery {
    allSanityTraning(sort: { order: ASC, fields: beginningDate }) {
      edges {
        node {
          id
          title
          beginningDate(formatString: "MMM DD, YYYY")
          applyNowLink
          area
          slug {
            current
          }
          category {
            title
          }
          mainBanner {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`
